import { useEffect } from 'react';
import * as Sentry from '@sentry/nextjs';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { NextSeo } from 'next-seo';

import { useTranslation } from '@hooks';
import { Error, Subtitle2 } from '@components/styles/Typography';
import { NewButton } from '@components/ui';

const NotFoundImage = () => {
    const { t } = useTranslation();
    const router = useRouter();

    useEffect(() => {
        Sentry.captureMessage(`404 Not Found: ${router.asPath}`, 'warning');
    }, []);

    return (
        <>
            <NextSeo
                title={`${t('ferventeTitle')} ${t('pageNotFoundTitle')}`}
            />
            <NotFoundWrapper>
                <NotFoundContainer>
                    <StyledLogo src="https://cloud.keikei.com/cdn/fervente/favicon.ico" />
                    <Error>404</Error>
                    <Subtitle2 uppercase>YOU COME TO THE WRONG PLACE</Subtitle2>
                    <NewButton
                        type="primary"
                        size="large"
                        content={t('turnHomepage')}
                        handleClick={() => router.push('/')}
                    />
                </NotFoundContainer>
            </NotFoundWrapper>
        </>
    );
};

const NotFoundWrapper = styled.div`
    width: 100%;
    height: calc(100vh - 120px);
    margin: 100px 0;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        margin-top: 0;
        height: calc(100vh - 50px);
    }
`;

const NotFoundContainer = styled.div`
    max-width: 650px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    p {
        margin: 10px 0 20px;
    }
`;

const StyledLogo = styled.img`
    max-width: 170px;
    margin-bottom: 40px;
`;

export default NotFoundImage;
